import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie'; // Import js-cookie

function UrlMapper({ token }) {
  const [assignedUrl] = useState(Cookies.get('assignedUrl'));
  const [targetUrl, setTargetUrl] = useState(Cookies.get('targetUrl'));
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = Cookies.get('authToken'); // Get the token from cookies

    try {
      const response = await axios.post(
        'https://server.dexterrtech.com:5000/api/map-url',
        { assignedUrl, targetUrl },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Mapping failed');
    }
  };

  return (
    <div className="form-container">
      <h2>Map URL</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Target URL:</label>
          <input
            type="text"
            value={targetUrl}
            onChange={(e) => setTargetUrl(e.target.value)}
            required
          />
        </div>
        <button type="submit">Map URL</button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
}

export default UrlMapper;
