import React, { useState } from "react";
import UrlMapper from "./components/UrlMapper";
import Login from "./components/Login";
import "./App.css";
import Cookies from "js-cookie"; // Import js-cookie

function App() {
  const [token, setToken] = useState(null);
  const userName = Cookies.get("user");

  return (
    <div className="App">
      <img src={require('./dexterr-logo.png')} alt="Logo" className="logo" />
      <h1>URL Mapping Portal</h1>
      {!token ? (
        <>
          <Login setToken={setToken} />
        </>
      ) : (
        <div>
          <h2>{userName}</h2>
          <UrlMapper token={token} />
        </div>
      )}
    </div>
  );
}

export default App;
