import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie'; // Import js-cookie
import './Login.css'; // Import the CSS file

function Login({ setToken }) {
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://server.dexterrtech.com:5000/api/login', {
        mobileNumber,
        password,
      });
      setToken(response.data.token);
      Cookies.set('authToken', response.data.token, { expires: 1 }); // Set the token in cookies (expires in 1 day)
      Cookies.set('user', response.data.user.userName);
      Cookies.set('assignedUrl', response.data.user.assignedUrl);
      Cookies.set('targetUrl', response.data.user.targetUrl);
      setMessage('Login successful');
    } catch (error) {
      setMessage('Login failed');
    }
  };

  return (
    <div className="form-container">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Mobile Number:</label>
          <input
            type="tel"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
}

export default Login;
